import { MarkerType } from "@xyflow/react";
import { actionNodeHeight, triggerNodeHeight } from "./nodes/nodeHeight";
import ELK from "elkjs/lib/elk.bundled";
import { useTranslation } from "react-i18next";

const elk = new ELK();

const NODE_WIDTH = 280;

export default async function generateNodesAndEdges(
  hasTriggers,
  actions,
  triggers,
) {
  const nodes = [];
  const edges = [];

  function addNode(nodeId, options) {
    nodes.push({
      id: nodeId,
      width: NODE_WIDTH,
      ...options,
    });
  }

  function addEdge(source, target, options) {
    edges.push({
      id: `e_${source}_${target}`,
      source: source.toString(),
      target: target.toString(),
      type: "insertable",
      markerEnd: {
        type: MarkerType.Arrow,
      },
      ...options,
    });
  }

  // function to add the new action node at the end of a branch
  // id: `newAction_${action.id}` or `newAction_trigger`
  function addNewActionNode(parentNodeId = null, data = {}) {
    const newActionId = `newAction_${parentNodeId}`;
    if (parentNodeId) addEdge(parentNodeId, newActionId, { type: "normal" });

    addNode(newActionId, {
      height: actionNodeHeight("newAction"),
      type: "newAction",
      data,
    });
  }

  // Custom functions for specific action types
  const customChildren = {
    ask_question: (action) => {
      const parentId = action.id.toString();

      // option branches
      action.options.ask_question_options?.forEach((option, index) => {
        if (!option.title) return;
        addBranchNode(parentId, `option_${index}`, "ask_question_option", {
          title: option.title,
        });
      });

      // expiration branch with action data
      addBranchNode(parentId, "option_expired", "branch_expiration", {
        label: "automation.scenarios.actions.ask_question.no_replies",
        ...action,
      });
    },
    wait_for_reply: (action) => {
      const parentId = action.id.toString();

      // when replied branch
      addBranchNode(parentId, "option_replied", "branch_option", {
        label:
          "automation.scenarios.actions.wait_for_reply.when_contact_replies",
      });

      // expiration branch with action data
      addBranchNode(parentId, "option_expired", "branch_expiration", {
        label: "automation.scenarios.actions.wait_for_reply.no_reply",
        ...action,
      });
    },
  };

  // recursive function to add the action nodes/edges then their children
  // id: action_id.toString()
  function addItem(action, parentNodeId = null) {
    if (!action) return;
    if (action && !action._destroy) {
      // create edge from previous node
      if (parentNodeId) addEdge(parentNodeId, action.id.toString());

      // create the action node
      addNode(action.id.toString(), {
        height: actionNodeHeight(action),
        type: action.action_type,
        data: { ...action },
      });

      // check if the action has a custom children function
      if (customChildren[action.action_type]) {
        customChildren[action.action_type](action);
      } else {
        addChildren(action.id.toString());
      }
    }
  }

  function addBranchNode(nodeId, branchId, type, data = {}) {
    const branchNodeId = `${nodeId}_${branchId}`;
    addEdge(nodeId, branchNodeId, {
      type: "normal",
    });
    addNode(branchNodeId, {
      height: actionNodeHeight({ action_type: type }),
      type,
      data,
    });

    addChildren(nodeId, branchId);
  }

  function addChildren(parentId, parentBranch = null) {
    // get the children of the action on this specific branch
    const children = actions.filter(
      (a) =>
        a.parent_id?.toString() == parentId && a.parent_branch == parentBranch,
    );

    const parentNodeId = parentBranch
      ? `${parentId}_${parentBranch}`
      : parentId.toString();

    // if the action has children, call the recursive function on each child
    if (children?.length > 0) {
      children?.forEach((child) => addItem(child, parentNodeId));
    } else {
      // else add a new action node
      addNewActionNode(parentNodeId, {
        parent_id: parentId,
        parent_branch: parentBranch,
      });
    }
  }

  // Trigger node at the start (id: "trigger")
  if (hasTriggers) {
    addNode("trigger", {
      height: triggerNodeHeight(triggers),
      type: "trigger",
    });
  }

  // First action node
  const firstAction = actions.find(
    (action) => !action.parent_id && !action._destroy,
  );
  if (firstAction) {
    addItem(firstAction, hasTriggers ? "trigger" : null);
  } else {
    addNewActionNode(hasTriggers ? "trigger" : null);
  }

  // Graph layout

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.alignment": "LEFT",
      "elk.direction": "DOWN",
      "elk.spacing.nodeNode": 104,
      "elk.layered.spacing.nodeNodeBetweenLayers": 80,
      "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES",
    },
    children: nodes.map((node) => ({
      ...node,
      targetPosition: "top",
      sourcePosition: "bottom",
    })),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const graphResult = await elk.layout(graph);

  const graphNodes = nodes.map((node) => {
    const graphNode = graphResult.children.find(
      (child) => child.id === node.id,
    );

    return {
      ...node,
      height: null,
      position: {
        x: graphNode.x,
        y: graphNode.y,
      },
    };
  });

  return {
    nodes: graphNodes,
    edges,
  };
}
