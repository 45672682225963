import {
  BeakerIcon,
  ChatBubbleLeftIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";

import validateAction from "../flow/validateAction";

import scenarioStatusIcon from "~/utils/scenarioStatusIcon";
import Button from "~/components/elements/Button";
import Toggle from "~/components/shared/Toggle";
import InvisibleInput from "~/components/utils/InvisibleInput";
import Loader from "~/components/utils/Loader";

import { isMobile } from "react-device-detect";
import { DirtyContext } from "~/contexts/dirty-context";
import FlowWrapper from "../flow/FlowWrapper";
import Flow from "../flow/Flow";
import { Panel } from "@xyflow/react";
import validateTrigger from "../flow/triggers/validateTrigger";
import { delay, every } from "lodash";
import { UserContext } from "~/contexts/user-context";
import Input from "../../elements/Input";
import InputSelect from "../../elements/InputSelect";
import ButtonWithDropdown from "../../elements/ButtonWithDropdown";
import ContactPickerDialog from "../../contacts/ContactPickerDialog";

export default function Scenario(props) {
  const { scenarioId, handleClose } = props;

  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { showAlert, showPrompt } = useContext(UIContext);
  const { dirty, setDirty } = useContext(DirtyContext);

  const { updateScenario, deleteScenario, testScenario, loadScenario } =
    useContext(AutomationContext);

  const [initialScenario, setInitialScenario] = useState({});
  const [scenario, setScenario] = useState({});

  const handleScenarioChange = (field, value) => {
    setScenario({ ...scenario, [field]: value });
    setDirty(true);
  };

  const handleInputChange = (evt) => {
    handleScenarioChange(evt.target.name, evt.target.value);
  };

  const handleDeleteScenario = () => {
    deleteScenario(parseInt(scenarioId), handleClose);
  };

  const handleToggleScenario = (active) => {
    if (!scenarioId) return;

    showPrompt(
      active
        ? t("automation.scenarios.start_confirm")
        : t("automation.scenarios.pause_confirm"),
      async () => {
        setScenario(await updateScenario(scenario.id, { active }));
      },
    );
  };

  // loading

  const [loading, setLoading] = useState(true);

  const loadScenarioData = useCallback(async () => {
    if (!scenarioId) return;
    setLoading(true);
    const res = await loadScenario(scenarioId);

    setInitialScenario(res);
    setScenario(res);
    setInitialActions(res.actions);
    setActions(res.actions);
    setInitialTriggers(res.triggers);
    setTriggers(res.triggers);
    setDirty(false);
    setLoading(false);
  }, [scenarioId]);

  // Trigger

  const [initialTriggers, setInitialTriggers] = useState([]);
  const [triggers, setTriggers] = useState([]);

  // Actions

  const [initialActions, setInitialActions] = useState([]);
  const [actions, setActions] = useState([]);

  // save scenario

  const [saving, setSaving] = useState(false);

  const validateSave = useCallback((scenario, actions, triggers) => {
    const isSaveValid = every([
      scenario?.title?.length,
      triggers.every(validateTrigger),
      actions.every(validateAction),
    ]);
    return isSaveValid;
  }, []);

  const isSaveValid = useMemo(
    () => validateSave(scenario, actions, triggers),
    [scenario, actions, triggers],
  );

  const scenarioPayload = useMemo(
    () => ({
      ...scenario,
      actions,
      triggers_attributes: triggers.map((trigger) => {
        if (trigger.id.toString().includes("temp_")) {
          return { ...trigger, id: null };
        } else {
          return trigger;
        }
      }),
    }),
    [scenario, actions, triggers],
  );

  const handleSave = useCallback(async () => {
    if (!isSaveValid) return;

    setSaving(true);

    const res = await updateScenario(scenarioId, scenarioPayload);

    setScenario(res);
    setInitialScenario(res);
    setActions(res.actions);
    setInitialActions(res.actions);
    setTriggers(res.triggers);
    setInitialTriggers(res.triggers);
    setDirty(false);

    setSaving(false);

    if (!scenario.active) {
      handleToggleScenario(true);
    }
  }, [scenario, actions, triggers]);

  const handleCancel = () => {
    setScenario(initialScenario);
    setActions(initialActions);
    setTriggers(initialTriggers);
    setDirty(false);
  };

  // Run test
  const [testDialog, setTestDialog] = useState(false);

  const handleTest = async (contactId) => {
    if (!isSaveValid) return;

    const res = await testScenario(scenarioPayload, contactId);

    setScenario(res);
    setInitialScenario(res);
    setActions(res.actions);
    setInitialActions(res.actions);
    setTriggers(res.triggers);
    setInitialTriggers(res.triggers);
    setDirty(false);

    // delay to wait for the previous alert to close
    delay(() => showAlert(t("automation.scenarios.test_sent")), 500);
  };

  const secondaryActions = useMemo(
    () => [
      {
        label: t("automation.scenarios.run_test"),
        icon: ChatBubbleLeftIcon,
        onClick: () => setTestDialog(true),
        disabled: !isSaveValid,
      },
    ],
    [isSaveValid],
  );

  // On page change
  useEffect(loadScenarioData, [scenarioId]);

  const options = useMemo(
    () =>
      user.admin
        ? ["one_shot", "interrupt_on_message", "is_template"]
        : ["one_shot", "interrupt_on_message"],
    [user],
  );

  const renderOptions = useCallback(
    () => (
      <div className="space-y-3">
        {options.map((field) => (
          <div className="flex items-center space-x-2" key={field}>
            <Toggle
              value={scenario[field] || false}
              onChange={(value) => handleScenarioChange(field, value)}
            />
            <div className="text-md">
              {t("automation.scenarios.settings." + field)}
            </div>
          </div>
        ))}
        {user.admin && scenario.is_template && (
          <>
            <InputSelect
              placeholder={"Template icon"}
              value={scenario.template_icon}
              onChange={(value) => handleScenarioChange("template_icon", value)}
              options={[
                "funnel",
                "heart",
                "bookmark",
                "chart-bar",
                "light-bulb",
                "megaphone",
                "shopping-bag",
                "sparkles",
                "tag",
                "star",
              ]}
            />
            <Input
              type="textarea"
              placeholder={"Description"}
              value={scenario.template_description}
              onChange={(value) =>
                handleScenarioChange("template_description", value)
              }
            />
          </>
        )}
      </div>
    ),
    [scenario, handleScenarioChange],
  );

  const renderSaveButtons = useCallback(
    () => (
      <>
        <Button label={t("shared.cancel_changes")} onClick={handleCancel} />
        <Button
          label={t("automation.scenarios.save")}
          style="primary"
          disabled={!isSaveValid}
          onClick={handleSave}
          loading={saving}
        />
      </>
    ),
    [handleSave, handleCancel, loading],
  );

  return (
    <div className="w-full flex-grow flex flex-col">
      <div className="p-3 sm:p-4 bg-white border-b flex-shrink-0 flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          {scenarioStatusIcon(scenario.active)}
          <InvisibleInput
            name="title"
            placeholder={t("automation.scenarios.title_placeholder")}
            value={scenario?.title}
            onChange={handleInputChange}
            autoFocus={!scenarioId}
            className="text-medium p-1 placeholder:italic border-none outline-none bg-transparent"
          />
        </div>
        <div className="flex space-x-3">
          <Button
            label={t("automation.scenarios.delete")}
            onClick={handleDeleteScenario}
            style="danger"
          />
          {scenarioId ? (
            scenario.active ? (
              <Button
                label={t("automation.scenarios.pause")}
                onClick={() => handleToggleScenario(false)}
                icon={PauseIcon}
                disabled={dirty}
              />
            ) : (
              <Button
                label={t("automation.scenarios.start")}
                onClick={() => handleToggleScenario(true)}
                icon={PlayIcon}
                style="primary"
                disabled={dirty}
              />
            )
          ) : null}
        </div>
      </div>
      <div className="sm:hidden p-3 bg-white border-b flex-shrink-0 space-y-3">
        {renderOptions()}
      </div>
      {loading ? (
        <div className={`flex-grow flex items-center justify-center`}>
          <Loader width={30} strokeWidth={6} />
        </div>
      ) : (
        <div className={`flex-grow relative`}>
          <FlowWrapper>
            <Flow
              hasTriggers
              triggers={triggers}
              setTriggers={setTriggers}
              actions={actions}
              setActions={setActions}
            >
              <Panel
                position="top-left"
                className="hidden sm:block p-3 rounded-lg bg-white border"
              >
                {renderOptions()}
              </Panel>
              <Panel
                position={isMobile ? "bottom-right" : "top-right"}
                className="hidden sm:flex space-x-2"
              >
                {dirty ? (
                  renderSaveButtons()
                ) : (
                  <Button
                    label={t("automation.scenarios.run_test")}
                    icon={BeakerIcon}
                    onClick={() => setTestDialog(true)}
                    disabled={!isSaveValid}
                  />
                )}
              </Panel>
            </Flow>
          </FlowWrapper>
        </div>
      )}
      {testDialog && (
        <ContactPickerDialog
          confirmPrompt={t("automation.scenarios.run_test_confirm")}
          onClose={() => setTestDialog(false)}
          onChange={handleTest}
        />
      )}
      {dirty && (
        <div className="flex sm:hidden p-3 bg-white border-t flex-shrink-0 justify-between items-center">
          {renderSaveButtons()}
        </div>
      )}
    </div>
  );
}
