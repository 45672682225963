import { sum } from "lodash";

export const triggerNodeHeight = (triggers) => {
  const triggersHeight = sum(
    triggers
      .filter((t) => !t._destroy)
      .map((trigger) => 32 + 16 + triggerContentHeight(trigger)),
  );
  return triggersHeight + 128;
};

const triggerContentHeight = (trigger) => {
  switch (trigger?.trigger_type) {
    case "messages":
      if (trigger.options.messages_option === "keywords") {
        return 166 + trigger.options.keywords?.length * 44;
      } else {
        return 78;
      }
    case "outgoing_message":
      if (trigger.options.outgoing_message_option === "keywords") {
        return 166 + trigger.options.keywords?.length * 44;
      } else {
        return 78;
      }
    case "referral_ad":
      if (trigger.options.referral_ad_option === "keywords") {
        return 221 + trigger.options.keywords?.length * 44;
      } else if (trigger.options.referral_ad_option === "ad_id") {
        return 221;
      } else {
        return 78;
      }
    case "story_reply":
      if (trigger.options.story_reply_option === "keywords") {
        return 166 + trigger.options.keywords?.length * 44;
      } else if (trigger.options.story_reply_option === "specific") {
        return 126;
      } else if (trigger.options.story_reply_option === "caption_keyword") {
        return 204;
      } else {
        return 78;
      }
    case "story_mention":
      return 26;
    case "comment":
      if (trigger.options.comment_option === "keywords") {
        return 166 + trigger.options.keywords?.length * 44;
      } else if (trigger.options.comment_option === "specific") {
        return 126;
      } else if (trigger.options.comment_option === "caption_keyword") {
        return 204;
      } else {
        return 78;
      }
    case "mention_comment":
      return 54;
    case "mention_media":
      return 54;
    case "referral":
      return 296;
    case "webhook":
      return 180;
    default:
      return 54;
  }
};

export const actionNodeHeight = (action) => {
  switch (action?.action_type) {
    case "message":
      // TODO calculate height based on content length taking into account line breaks and character width
      return Math.ceil((action.content?.length || 1) / 28) * 20 + 20;
    case "audio_message":
      return action.options?.media_file_id ? 64 : 72;
    case "share_media":
      return action.options?.media_ig_id ? 325 : 88;
    case "engagement_condition":
      return 88;
    case "comment":
      return 82;
    case "collect_email":
      return 82;
    case "wait_for_reply":
      return 86;
    case "folder":
      return 64;
    case "update_property":
      return 178;
    case "delay":
      return 64;
    case "survey":
      return action.options?.survey_id
        ? 100 + (action.survey?.survey_options?.length || 0) * 44
        : 46;
    case "conversion_link":
      return action.options?.conversion_link_id
        ? action.conversion_link?.image
          ? 485
          : 235
        : 46;
    case "ask_question":
      return (
        216 + Math.min(action.options?.ask_question_options?.length + 1, 3) * 38
      );
    case "ask_question_option":
      return 30;
    case "checkout_link":
      return action.options?.products?.length ? 416 : 46;
    case "payment_link":
      return action?.options?.payment_amount ? 440 : 46;
    case "media_message":
      if (action?.dimensions) {
        const height = action?.dimensions.height;
        const width = action?.dimensions.width;
        const scaledWidth = 224;
        return (height * scaledWidth) / width;
      } else {
        return 180;
      }
    case "http_request":
      return (
        194 +
        (action.options?.http_headers?.length ? 24 : 0) +
        (action.options?.http_headers?.length || 0) * 44 +
        (action.options?.http_params?.length ? 24 : 0) +
        (action.options?.http_params?.length || 0) * 44
      );
    case "scenario_condition":
      return (
        114 + (action.options.condition_type === "message_contains" ? 44 : 0)
      );
    case "content_filter":
      return 118;
    case "hide_comment":
      return 110;
    case "branch_option":
      return 20;
    case "branch_expiration":
      return 30;
    case "trigger_scenario":
      return 122;
    default:
      return 40;
  }
};
